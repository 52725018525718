:root {
  --menu-width: 4rem;
}

.menu {
  width: var(--menu-width);
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--bg);
  border-left: var(--border);
  overflow: hidden;

  z-index: var(--z-menu);
  transition: width 0.4s ease-out, height 0.3s ease-out;

  scrollbar-color: #b9b9b9 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.menu__toggle {
  position: relative;
  z-index: 2;

  display: block;
  width: calc(var(--menu-width) / 2);
  height: calc(var(--menu-width) / 2);
  margin: calc(var(--menu-width) / 4);
  cursor: pointer;

  :where(.icon) {
    display: block;
  }

  @media (--media-md) {
    position: absolute;
    right: 0;
    top: 0;
  }
}

#sl-toggle,
.menu__submenu__toggle {
  pointer-events: none;
}

#sl-toggle {
  background: var(--bg);
  position: relative;
  height: 100%;
  width: 100%;

  &:before,
  &:after {
    display: block;
    background: var(--fg);
    backface-visibility: hidden;
    content: "";
    height: 2px;
    left: 0;
    transition: 0.3s;
    width: 100%;
  }

  &:before {
    top: 6px;
    box-shadow: var(--fg) 0 calc(var(--menu-width) / 4 - 7px) 0 0;
    position: absolute;
  }

  &:after {
    position: absolute;
    top: calc(100% - 8px);
  }

  .-menu-expanded & {
    &:before {
      box-shadow: transparent 0 0 0 0;
      top: 50%;
      transform: rotate(315deg);
    }

    &:after {
      top: 50%;
      transform: rotate(225deg);
    }
  }
}

.menu__close {
  display: none;
}

#expand-menu,
.expand-submenu {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

#expand-menu:checked {
  + .menu {
    width: 100vw;
    z-index: var(--z-top);

    .menu__close {
      display: block;
    }
    .menu__open {
      display: none;
    }
    .menu__menu {
      opacity: 1;
      transition: opacity 0.4s;
      transition-delay: 0.2s;
    }
  }
}

.menu__logo {
  background-color: var(--bg);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  :is(svg) {
    display: block;
    width: 7.5rem;
    height: 2.375rem;
  }

  @media (--media-md) {
    width: fit-content;
    align-self: center;
    right: 0;
    top: 0;
    left: auto;
    bottom: auto;
    transform-origin: center;
    transform: rotate(270deg) translate(-6.6rem, 1.7rem); /* magic numbers, i know */
  }

  @media (--media-sm-only) {
    width: 100%;
    height: var(--menu-width);
    display: grid;
    place-items: center;
  }
}

.menu__menu {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  opacity: 0;
  transition: opacity 0.2s;

  position: absolute;
  left: calc(var(--menu-width) + 10%);
  @media (--media-lg) {
    left: calc(var(--menu-width) + 20%);
  }
  right: var(--menu-width);
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;

  &[aria-hidden="true"] {
    pointer-events: none;
  }

  &[aria-hidden="false"] {
    pointer-events: all;
  }
}

.menu__link {
  color: inherit;
  font-size: var(--fs-main-nav);
  white-space: nowrap;
  text-decoration: none;

  &:not(.menu__link--submenu):hover,
  &:focus-visible,
  &.active {
    text-decoration: underline;
    color: inherit;
  }
}

.menu__social {
  display: flex;
  gap: 10px;
  padding-top: var(--gap);

  :is(a) {
    color: inherit;
    text-decoration: none;
    display: grid;
    place-items: center;
  }

  .icon {
    --size: 2em;
  }
}

@media (--media-sm-only) {
  .menu {
    top: 0;
    right: 0;
    left: 0;
    bottom: auto;
    height: var(--menu-width);
    width: auto;
    border: none;
    border-bottom: var(--border);
  }

  .menu ~ .body {
    margin-left: 0;
    margin-top: var(--menu-width);
  }

  .menu__menu {
    left: var(--gap);
    right: 0;
  }

  #expand-menu:not(:checked) + .menu .menu__social {
    display: none;
  }

  #expand-menu:checked {
    + .menu {
      height: 95vh;
      /* biome-ignore lint/nursery/noDuplicateProperties: dvh may not be supported everywhere */
      height: 100dvh;

      .menu__close {
        display: block;
      }
      .menu__open {
        display: none;
      }
    }
  }
}

.dark-mode {
  margin-top: auto;
  margin-left: auto;
  padding: calc(var(--menu-width) / 4);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  .icon {
    --size: calc(var(--menu-width) / 2);
  }

  @media (--media-md) {
    top: auto;
    bottom: 0;
  }
}

ul.menu__menu__list {
  height: 100%;
  width: 100%;
  padding-top: 15vh;
  overflow: auto;
}

.menu__menu__list-item {
  position: relative;
  max-width: 20rem;

  @media (--media-md) {
    max-width: min(35rem, 100%);
  }
}

.submenu__toggle {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    --size: clamp(3rem, 1.4rem + 4vw, 5rem);
  }
}

.menu__submenu__toggle {
  padding-top: 0.5em;

  @media (--media-md) {
    padding-top: 1.25em;
  }
}

.expand-submenu:checked + .menu__menu__list-item {
  .menu__submenu {
    display: block;
  }
  .submenu__open {
    display: none;
  }
  .submenu__close {
    display: block;
  }
}

.expand-submenu:not(:checked) + .menu__menu__list-item {
  .menu__submenu {
    display: none;
  }
  .submenu__open {
    display: block;
  }
  .submenu__close {
    display: none;
  }
}

/* XXX: still needed? */
/*   :first-child {
    position: relative;

    &[data-jobs]::after {
      content: attr(data-jobs);
      position: absolute;
      right: -0.5em;
      top: -0.6em;
      background-color: red;
      color: var(--white);
      width: 1.12em;
      height: 1.12em;
      line-height: 1em;
      text-align: center;
      border-radius: 50%;
      font-weight: 700;
    }
  } */
/* End eye-catcher */
