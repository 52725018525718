.prose {
  max-width: var(--prose-max-width);

  :is(h1, h2, h3, h4, h5, h6, p, ul, ol, dl) {
    max-width: var(--prose-max-width);
  }

  h1,
  h2 {
    overflow-wrap: break-word;
  }

  h3,
  h4 {
    + * {
      margin-top: 0;
    }
  }
}

.prose :where(a:not([class])) {
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  html[data-theme="dark"] & {
    color: #8ca8de;
  }
}

/* External link indicator for links opening in new windows */
a[target="_blank"]:not([class]) {
  display: inline-block;

  &::after {
    content: "";
    display: inline-block;

    width: 0.8em;
    height: 0.8em;
    margin-left: 0.2em;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='none' stroke='rgb(58, 102, 217)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6'%3E%3C/path%3E%3Cpolyline points='15 3 21 3 21 9'%3E%3C/polyline%3E%3Cline x1='10' y1='14' x2='21' y2='3'%3E%3C/line%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    transform: translateY(0.075em);
  }

  html[data-theme="dark"] &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='none' stroke='rgb(140, 168, 222)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6'%3E%3C/path%3E%3Cpolyline points='15 3 21 3 21 9'%3E%3C/polyline%3E%3Cline x1='10' y1='14' x2='21' y2='3'%3E%3C/line%3E%3C/svg%3E");
  }
}

.figure {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: var(--gap);

  [class^="directive"] &,
  [class*=" directive"] & {
    margin-bottom: 0;
  }

  + :where(.flow, p, ol, ul, h2, h3, h4) {
    margin-top: 0;
  }

  & img {
    margin: 0 auto;
    flex: 1 1 100%;
  }
}

.figcaption {
  margin-top: 1em;
}

.copyright {
  font-size: 10px;
  opacity: 0.7;
}

.video {
  max-width: 100%;
  margin: var(--gap) 0;

  + :where(.flow, p, ol, ul, h2, h3, h4) {
    margin-top: 0;
  }
}

.lead {
  font-size: var(--fs-h3);
}

.small-text {
  font-size: var(--fs-small);
}

.angles.large-prose {
  display: flex;
  align-items: center;
}
